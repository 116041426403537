exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bienestar-galeria-js": () => import("./../../../src/pages/bienestar-galeria.js" /* webpackChunkName: "component---src-pages-bienestar-galeria-js" */),
  "component---src-pages-bienestar-tecnica-js": () => import("./../../../src/pages/bienestar-tecnica.js" /* webpackChunkName: "component---src-pages-bienestar-tecnica-js" */),
  "component---src-pages-holding-js": () => import("./../../../src/pages/holding.js" /* webpackChunkName: "component---src-pages-holding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movilidad-galeria-js": () => import("./../../../src/pages/movilidad-galeria.js" /* webpackChunkName: "component---src-pages-movilidad-galeria-js" */),
  "component---src-pages-movilidad-tecnica-js": () => import("./../../../src/pages/movilidad-tecnica.js" /* webpackChunkName: "component---src-pages-movilidad-tecnica-js" */),
  "component---src-pages-ortopedia-galeria-js": () => import("./../../../src/pages/ortopedia-galeria.js" /* webpackChunkName: "component---src-pages-ortopedia-galeria-js" */),
  "component---src-pages-ortopedia-tecnica-js": () => import("./../../../src/pages/ortopedia-tecnica.js" /* webpackChunkName: "component---src-pages-ortopedia-tecnica-js" */),
  "component---src-pages-patio-js": () => import("./../../../src/pages/patio.js" /* webpackChunkName: "component---src-pages-patio-js" */)
}

